<template>
  <el-dialog :title="$t('event.callElevatorStatistics')" :close-on-click-modal="false" :visible.sync="dialogVisible"
    width="70%" top="5vh" @close="onDialogClose">
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="userTakeRecords/count"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.recordTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="userName" :label="$t('event.username')"></el-table-column>
      <el-table-column prop="userMobile" :label="$t('event.registerMobile')"></el-table-column>
      <el-table-column prop="count" :label="$t('event.callElevatorTimes')"></el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dateRange: [],
      search: {
        startTime: "",
        endTime: "",
        filter: "",
      },
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    onDialogClose() {
      this.$copy(this.$data, this.$options.data.call(this));
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
