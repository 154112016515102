<template>
  <div>
    <user-take-record-count ref="userTakeRecordCount"></user-take-record-count>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="userTakeRecords" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.recordTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.userTakeRecordCount.open()">
          {{ $t("event.callElevatorStatistics") }}
        </el-button>
      </template>
      <el-table-column prop="userName" :label="$t('event.username')"></el-table-column>
      <el-table-column prop="userMobile" :label="$t('event.registerMobile')"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('event.implementationElevator')">
        <template slot-scope="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorID" target="_blank">{{ scope.row.elevatorName
            }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="address" :label="$t('event.userLocation')"></el-table-column>
      <el-table-column prop="currentFloor" :label="$t('event.currentFloor')"></el-table-column>
      <el-table-column prop="opertationData" :label="$t('event.destinationFloor')"></el-table-column>
      <el-table-column prop="opertationType" :label="$t('event.operationType')">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.opertationType === 1" type="success">{{ $t('event.externalCallUp') }}</el-tag>
          <el-tag v-else-if="scope.row.opertationType === 2" type="success">{{ $t('event.externalCallDown') }}</el-tag>
          <el-tag v-else-if="scope.row.opertationType === 3">{{ $t('event.carCall') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="$t('event.recordTime')" width="160" align="center"></el-table-column>
      <el-table-column prop="note" :label="$t('event.remark')" width="100" align="center"
        show-overflow-tooltip></el-table-column>
    </vm-table>
  </div>
</template>
<script>
import UserTakeRecordCount from "./UserTakeRecordCount.vue";

export default {
  components: { UserTakeRecordCount },
  data() {
    return {
      loading: false,
      timeFilter: "",
      dateRange: [],
      search: {
        startTime: "",
        endTime: "",
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
<style lang="scss" scoped></style>
